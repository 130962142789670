import React, { useState, useEffect } from 'react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import './Testimonial.scss'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import { PortableText } from '@portabletext/react'



const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleClick = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    const query = '*[_type == "testimonials"] | order(order asc)'

    client.fetch(query)
      .then((data) => {
        setTestimonials(data)
      })
  }, [])

  // Create a variable for easy re-use purpose:
  const test = testimonials[currentIndex]

  return (
    <>
      {testimonials.length && (
        <>
          <h1 className="head-text">Nice things people have said</h1>
          <div className="testimonial__item app__flex">
            <div className="testimonial__card">
              <div className="testimonial__text">
                <PortableText value={test.feedback} />
              </div>
              <div className="testimonial__info">
                <img src={urlFor(test.imgurl)} alt={test.name} loading="lazy" />
                <div className="testimonial__titles">
                  <h3>{test.name}</h3>
                  <h4>{test.company}</h4>
                </div>
              </div>
            </div>
            <div className="testimonial__buttons app__flex">
              <button className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
                  <HiChevronLeft />
              </button>
              <button className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
                  <HiChevronRight />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AppWrap(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonials',
  'app__primarybg'
)