import React, { useState } from 'react'
import { Link } from 'react-scroll'
import './Navbar.scss'
import { images } from '../../constants'
import { HiMenuAlt4, HiX } from 'react-icons/hi'
import { motion } from 'framer-motion'

const Navbar = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <nav className="app__navbar">
      <div className="navbar__logo">
        <Link to="home" spy={true} smooth={true} offset={-15} duration={50}  activeClass="logo">
          <img src={images.logo} alt="logo" />
        </Link>
      </div>
      <ul className="navbar__links">
        {['about', 'work', 'skills', 'testimonials', 'contact'].map((item) => (
          <li className='app__flex p-text' key={`link-${item}`}>
            <Link activeClass="active" to={`${item}`} spy={true} smooth={true} offset={-11} duration={50}>{item}</Link>
          </li>
        ))}
      </ul>
      <div className="navbar__mobile">
          <HiMenuAlt4 onClick={() => setToggle(true)}/>
          {toggle && (
              <motion.div
                initial="hidden"
                whileInView={{ x: [200, 0] }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
              >
                <HiX onClick={() => setToggle(false)} />
                <ul>
                {['home', 'about', 'work', 'skills', 'testimonials', 'contact'].map((item, index) => (
                  <li key={item + index}>
                    <Link 
                      to={item} 
                      onClick={() => setToggle(false)} 
                      spy={true} 
                      smooth={true} 
                      offset={-10} 
                      duration={50}
                      activeClass="active"
                    >
                      {item}
                    </Link>
                  </li>
                ))}
                </ul>
              </motion.div>
            )
          }
      </div>
    </nav>
  )
}

export default Navbar