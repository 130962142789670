import React from 'react'
import './Header.scss'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'

import { AppWrap } from '../../wrapper'


const Header = () => {
  return (
    <div className="app__header app__flex portrait">
      <motion.div 
        className="header__content"
        whileInView={{ y: [100, 0], opacity: [0, 1] }}
        transition={{ duration: 1.3, ease: 'easeInOut' }}
      >
        <h1
          className="header__name"
          >
          Julie Lai
        </h1>
        <h2 className="header__title">Front-End Developer</h2>
        <motion.p 
          className="header__subtext"
          whileInView={{ opacity: [0, 1], x: [-150, 0] }}
          transition={{ duration: 1.6, ease: 'easeInOut', delay: 0.3 }}

        >
          Building web apps with a quality-driven mind
        </motion.p>
        <Link
          to="about"
          className="header__cta"
          >
          <motion.button 
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1.5, ease: 'easeInOut', delay: 0.3 }}
            >
            Explore
          </motion.button>
        </Link>
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home')