import React, { useState, useRef } from 'react'
import './Footer.scss'
import 'whatwg-fetch'
import { AppWrap, MotionWrap } from '../../wrapper'
import { AiFillGithub, AiOutlineLinkedin, AiOutlineCodepen } from 'react-icons/ai'
import emailjs from 'emailjs-com'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData

  const handleChangeInput = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  const form = useRef();

  const formSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    emailjs.sendForm('service_94nxipi', 'template_936ztr6', form.current, 'MMeCrW67dCuN63l9Q')
      .then((result) => {
        console.log(`Message status: ${result.text}`);
          setLoading(false)
          setIsFormSubmitted(true)
      }, (error) => {
          console.log(`Sending message failed: ${error.text}`)
      })
  }

  const sendAnother = (e) => {
    e.preventDefault()
    setIsFormSubmitted(false)
    setFormData({ name: '', email: '', message: '' })
  }

  return (
    <>
      <h2 className="head-text">Get in touch with me</h2>
      <div className="footer__links">
        <a href="https://www.linkedin.com/in/tjulielai/" target="_blank" rel="noreferrer">
          <AiOutlineLinkedin />
        </a>
        <a href="https://github.com/higherstates" target="_blank" rel="noreferrer">
          <AiFillGithub />
        </a>
        <a href="https://codepen.io/higherstates" target="_blank" rel="noreferrer">
          <AiOutlineCodepen />
        </a>
      </div>
      {!isFormSubmitted 
        ? <div className="footer__text">
          <p>Drop a line to say "hi", ask for my resume or see if we can build something amazing together?</p>
          <p>I'd love to hear from you!</p>
        </div>
        : <div className="form__response app__flex">
            <h3>Thanks for your kind words</h3>
            <p>Wonderful! Your message has probably reached my inbox by now.<br/>I read every email sent and do try to get back as soon as I can.<br/> Have a nice day!</p>
            <button onClick={sendAnother}>Submit Another</button>
        </div>
      }

      {!isFormSubmitted && 
        <form className="footer__form app__flex" onSubmit={formSubmit} ref={form}>
          <div className="app__flex">
            <input 
              type="text" 
              className="p-text" 
              placeholder="Name *" 
              value={name} 
              onChange={handleChangeInput} 
              name="name" 
              autoComplete="off" 
              required="required" 
            />  
          </div>
          <div className="app__flex">
            <input 
              type="email" 
              className="p-text" placeholder="Email *" 
              value={email} 
              onChange={handleChangeInput} name="email" 
              autoComplete="off" required="required" 
            />  
          </div>
          <div>
            <textarea 
              className="p-text" placeholder="Message *"
              value={message}
              name="message"
              onChange={handleChangeInput}
              rows="5"
              required="required"
            />
          </div>
          <button type="submit">{loading ? 'Sending...' : 'Send Email'}</button>
        </form>
      }
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)