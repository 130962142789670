import React, { useState, useEffect } from 'react'
import './About.scss'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { client } from '../../client'
import images from '../../constants/images'


const About = () => {
  const [abouts, setAbouts] = useState([])
  
  useEffect(() => {
    const query = '*[_type == "abouts"] | order(order asc)'
    
    client.fetch(query)
    .then((data) => setAbouts(data))
    
  }, [])
  
  return (
    <>
      <h2 className="head-text">
      Hello, And Welcome
      </h2>
      <div className="about__content">
        <div className="about__text">
          <p>
            My name is <b>Julie</b>, I'm a <b>Front-End Developer</b> specializing in <b>React JS</b>.
          </p>
          <p>
            I'm a <b>lifelong learner</b> or you could say I'm a nerd that loves learning new things.
            I'm happiest when I'm creating, learning, exploring and thinking about how to make things better.
            <br />
            <b>Web Development</b> is a chosen path for me to convey all my ideas into functional works — this practice allows me to inhabit the space where art and science collide.
          </p>
          <p>
            This website is built using <a href='https://reactjs.org/' target="_blank" rel="noreferrer">React</a>, <a href='https://sass-lang.com/' target="_blank" rel="noreferrer">Sass</a>, <a href='https://www.sanity.io/' target="_blank" rel="noreferrer">Sanity</a> & <a href="https://www.framer.com/motion/">Framer Motion</a>. I hope it conveys at least a small part of my enthusiasm for the craft.
            <br />
            Thanks for stopping by!
          </p>

        </div>
        <div className="about__img">
          <motion.img 
            src={images.flowers} alt="" 
            whileInView={{ opacity: [0, 1], x: [100, 0] }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
          />
        </div>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'), 
  'about',
  'app__whitebg'
)