import React, { useState, useEffect } from 'react'
import './Work.scss'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import { PortableText } from '@portabletext/react'


const Work = () => {
  const [animateCard] = useState({ y: 0, opacity: 1 })

  const [works, setWorks] = useState([])

  const [filterWork, setFilterWork] = useState([])

  useEffect(() => {
    const query = '*[_type == "works"]  | order(order asc)'

    client.fetch(query)
      .then((data) => {
        setWorks(data)
        setFilterWork(data)
      })
  }, [])

  return (
    <>
      <h2 className="head-text">
        My Personal Projects
      </h2>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5}}
        className="work__portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="work__item app__flex" key={index}>
            <div className="work__content">
              <div className="work__img">
                <img src={urlFor(work.imgUrl)} alt={work.name} />
              </div>
              <div className="work__text">
                <h4>{work.title}</h4>
                <PortableText value={work.description} />
                <div className="work__tags">
                {work.tags.map((tech, index) => (
                  <span key={index}>{tech}</span>
                ))}
                </div>
                <div className="work__buttons">
                  <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <AiFillEye />Live
                  </a>
                  <a href={work.codeLink} target="_blank" rel="noreferrer">
                    <AiFillGithub />Code
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Work, 'app__works'),
  'work',
  'app__primarybg'
)