import React, { useState, useEffect } from 'react'
import './Skills.scss'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import { Link } from 'react-scroll'


const Skills = () => {
  const [skills, setSkills] = useState([])

  useEffect(() => {
    const skillsQuery = '*[_type == "skills"] | order(order asc)'

    client.fetch(skillsQuery)
    .then((data) => {
      setSkills(data)
    })
  }, [])

  return (
    <>
      <h2 className="head-text">Skills & Tools</h2>
      <div className="skills__text">
        <p>These are the main technologies that I use to bring your products to life</p>
        <small>
          Can't find your preferred tools here? Feel free to <Link to="contact">ask me</Link> what more I can do :)
        </small>
      </div>
      <div className="skills__container">
        <motion.div className="skills__list">
          {skills.map((skill) => (
            <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className="skills__item app__flex"
            key={skill.name}
            >
              <div className="app__flex">
                <img src={urlFor(skill.icon)} alt={skill.name} draggable="false" />
              </div>
              <p>{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg'
)